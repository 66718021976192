@import "src/assets/stylessheets/variables"

.language-selector
    padding-bottom: $grid-gutter*2
    list-style: none
    margin: 0 $grid-gutter*2

    li
        cursor: pointer
        font-size: 14px
        border-top: 1px solid $silver
        padding: $grid 0

        &:hover
            background: $wet-asphalt

        &.active
            background: $wet-asphalt
            position: relative

            &::before
                content: ""
                position: absolute
                left: 4px
                top: 8px
                width: 15px
                aspect-ratio: 1
                background: $white
                clip-path: polygon(50% 0,79% 90%,2% 35%,98% 35%,21% 90%)

