@import "src/assets/stylessheets/variables"

.component.invoice
    background: $white

    .wrapper
        border: 1px solid $silver
        max-width: 820px
        margin: 0 auto
        padding: $grid-gutter*2

    table
        width: 100%
        color: $midnight-blue
        line-height: 20px

#root > iframe
    width: 100%
    height: calc(100vh - 4px)
    border: none
    position: relative
