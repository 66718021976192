@import "src/assets/stylessheets/variables"
@import "src/assets/stylessheets/helpers/mixins"

.general-table
    width: 100%
    border-spacing: 0

    th,
    td
        color: $black
        padding: $grid
        text-align: left

        &.items
            text-align: right

        &.bold
            font-weight: bold

        &:first-of-type
            padding-left: $grid-gutter

        &:last-of-type
            padding-right: $grid-gutter

        &.id
            width: 80px

    th
        border-top: 1px solid $green-sea
        border-bottom: 1px solid $green-sea
        font-weight: bold
        background: #f6f6f6

        &.actions
            padding-right: 44px
            text-align: right

    td
        border-top: 1px solid #e2e5e8

    tbody
        tr:hover,
        tr:hover + tr.comment,
        tr:has(+ tr.comment:hover)
            background: $hover

        td
            cursor: pointer

    .actions
        padding-left: $grid-gutter*2
        width: $grid-gutter*2
        white-space: nowrap
        text-align: right

        > div
            margin-left: $grid
            cursor: pointer

        > a
            color: $belize-hole

        .dot
            width: 4px
            height: 4px
            margin: 7px 2px
            background-color: $black
            border-radius: 50%
            display: inline-block

            &:nth-child(1)
                animation-delay: 0s

            &:nth-child(2)
                animation-delay: 0.2s

            &:nth-child(3)
                animation-delay: 0.4s

        > div
            display: inline-block
            position: relative
            top: 3px

    tfoot
        .title
            white-space: nowrap

        input
            width: 70px

    .error
        color: $alizarin
        font-weight: bold

    .no-items
        font-weight: 700
        text-align: center

        span
            cursor: pointer
            color: $midnight-blue
            font-weight: bold
            padding-right: 2px

            &:hover
                color: $asbestos
