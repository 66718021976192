@import "src/assets/stylessheets/variables"

.general-table.worksheet-table
    .locked
        width: 30px

    td.locked
        padding: 0 $grid

    svg.locked
        fill: $emerald
