@import "src/assets/stylessheets/variables"
@import "src/assets/stylessheets/helpers/mixins"

.component.order
    border-top: none

    .general-form
        padding: 0
        border-top: none

    .order-head
        padding: 0 $grid-gutter

    header
        position: sticky
        top: 0
        z-index: 1
        background: $white
        border-top: 3px solid $turquoise
        border-bottom: 1px solid $turquoise

    .customer-date
        display: grid
        grid-template-columns: 1fr 1fr
        padding: $grid-gutter 0
        gap: $grid

        .date
            position: relative

        .rdp
            position: absolute
            z-index: 1
            background: $white
            left: -16px
            right: -16px
            padding: $grid-gutter
            box-shadow: inset 0 0 0 1px $emerald
            margin-top: 0

            .rdp-months
                display: block

            .rdp-table
                width: 100%
                max-width: 100%

            .rdp-day_selected
                &,
                :focus-visible,
                :hover
                    background: $turquoise
                    font-weight: bold

            .rdp-button:hover:not([disabled]):not(.rdp-day_selected)
                background: $clouds

    .order-table
        display: table

        td
            padding: $grid calc($grid / 2)

            &:first-of-type
                padding-left: $grid

            &:last-of-type
                padding-right: $grid

        tbody input
            width: auto

        .id
            width: 120px

        .name
            width: 50%
            min-width: 200px

        .quantity
            width: 85px

        .price
            width: 85px

        .total-price
            width: 85px

        .discount-price
            width: 85px

        .total-discount
            width: 85px

        .discount
            width: 85px

        .actions
            width: 35px

        .quantity,
        .price,
        .discount-price,
        .discount
            input
                width: 85px

        .comment
            padding-top: 0
            border-top: none

            input
                width: 100%

        tbody td
            cursor: initial

        .add-button button
                margin: 0 auto

        .comment
            .label
                display: none

        tfoot
            td
                padding-top: 0
                padding-bottom: 0
                border: none
                text-align: right

            .title
                line-height: 40px

                input
                    width: 35px
                    margin: 0 calc($grid / 2)

        .win
            line-height: 41px
            padding-right: $grid-gutter
            font-weight: bold
            font-size: 18px
            color: $carrot

        input
            &[name=code],
            &[name=quantity],
            &[name=price],
            &[name=discount-price],
            &[name=shipping],
            &[name=discount-shipping],
            &[name=offer]
                text-align: right

            &[name=customer-discount]
                text-align: center

        .fake-input,
        .total,
        .win
            text-align: right
