@import "src/assets/stylessheets/helpers/mixins"

html,
#root
    min-height: 100vh

#root
    background: #E9E9E9
    color: $black
    position: relative

    &:not(.pdf)
        display: grid
        grid-template-columns: 200px 1fr
        grid-template-rows: 83px 1fr

    > svg
        position: absolute
        fill: #dddddd
        bottom: 0
        right: 0
        filter: blur(1px)

body
    color: $silver
    font-family: Roboto, Verdana, sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    margin: 0
    height: 100%

.component
    background: $white
    padding: 0
    border-top: 3px solid $turquoise
    margin: $grid-gutter
    height: fit-content
    position: relative

    header
        padding: $grid-gutter
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: column
        row-gap: $grid-gutter

        h1
            font-size: 20px
            color: #37474f

        @include for-tablet-landscape-up
            flex-direction: row

        .actions
            display: flex
            gap: $grid-gutter
            flex-wrap: wrap

.hidden
    display: none !important