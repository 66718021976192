@import "src/assets/stylessheets/variables"
@import "src/assets/stylessheets/helpers/mixins"

.dashboard
    padding: $grid-gutter
    height: fit-content

    .component
        min-height: 100%
        margin: 0

        &.orders
            grid-column: 1 / 3

    @include for-tablet-landscape-up
        display: grid
        gap: $grid-gutter
        grid-template-columns: 1fr 1fr
