@import "src/assets/stylessheets/variables"

h1, h2, h3, h4
    margin: 0

h1
    font-size: 24px

ul
    margin: 0
    padding: 0