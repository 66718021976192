@import "src/assets/stylessheets/variables"

.worksheets-per-customer
    .row.disabled td
        cursor: initial
        
    .invoice-dropdown
        position: absolute
        margin-top: -1px
        left: 0
        right: 0
        z-index: 1
        background: $white
        list-style: none
        border: 1px solid $silver

        ul
            list-style: none
            overflow-y: scroll
            max-height: 175px

            li
                padding: $grid 0 $grid $grid
                cursor: pointer

                &:hover
                    background: $li-hover
