@import "src/assets/stylessheets/variables"
@import "src/assets/stylessheets/helpers/mixins"

.products-table
    .comment
        display: grid
        grid-template-columns: 120px auto
        column-gap: 4px
        align-items: center
        margin-top: -4px
        padding-top: 4px
