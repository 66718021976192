@import "src/assets/stylessheets/variables"
@import "src/assets/stylessheets/helpers/mixins"

.button
    padding: $grid-gutter $grid-gutter*2
    border-radius: $grid/2
    position: relative
    overflow: hidden
    cursor: pointer
    border: 0 solid transparent
    transition: .3s
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center
    min-width: 60px
    user-select: none
    font-family: Roboto, Verdana, sans-serif
    font-size: 12px

    > span
        position: absolute
        text-align: center
        line-height: 1
        color: $white
        font-weight: 700

        a
            color: $white
            text-decoration: none

    &.large
        min-height: unquote($button-default-height + 'px')
        min-width: $button-min-width

        @include for-tablet-landscape-up
            min-height: unquote($button-desktop-height + 'px')

        &:hover
            border: 3px solid rgba(white, .3)

            @include for-tablet-landscape-up
                border: 9px solid rgba(white, .3)

        > span
            font-size: 18px

    &:not(.small):not(.large)
        min-height: 34px
        min-width: 80px

    &.small
        > span
            font-size: 14px

    &:active
        top: 1px

    &.primary
        background: $emerald

        > span
            text-shadow: md-text-shadow($button-default-height, darken($emerald, 5))

            @include for-tablet-landscape-up
                text-shadow: md-text-shadow($button-desktop-height, darken($emerald, 5))

    &.secondary
        background: $peter-river

        > span
            text-shadow: md-text-shadow($button-default-height, darken($peter-river, 5))

            @include for-tablet-landscape-up
                text-shadow: md-text-shadow($button-desktop-height, darken($peter-river, 5))

    &.tertiary
        background: $alizarin
        color: $white

        > span
            text-shadow: md-text-shadow($button-default-height, darken($alizarin, 5))

    &.quaternary
        background: $concrete

        > span
            text-shadow: md-text-shadow($button-default-height, darken($concrete, 5))

    &.icon
        min-width: 0
        padding: $grid-gutter
        width: unquote($button-default-height + 'px')
        color: white

        @include for-tablet-landscape-up
            width: unquote($button-desktop-height + 'px')

        svg
            fill: currentColor
            width: 20px
            position: absolute

            @include for-tablet-landscape-up
                width: 30px

    &.disabled
        background: $concrete
        pointer-events: none

        > span
            text-shadow: md-text-shadow($button-default-height, darken($asbestos, 5))

            @include for-tablet-landscape-up
                text-shadow: md-text-shadow($button-desktop-height, darken($asbestos, 5))
