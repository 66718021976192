@import "src/assets/stylessheets/variables"

.MuiPagination-root
    background: $white
    border-top: 1px solid $turquoise
    margin: 0
    padding: $grid-gutter $grid/2

    button
        color: $turquoise
        font-weight: 700

        &.Mui-selected
            background: $turquoise
            color: $white
