// de colores
$white: #ffffff
$black: #000000

// https://flatuicolors.com/palette/defo
$turquoise: #1abc9c
$green-sea: #16a085
$sun-flower: #f1c40f
$orange: #f39c12
$emerald: #2ecc71
$nephritis: #27ae60
$carrot: #e67e22
$pumpkin: #d35400
$peter-river: #3498db
$belize-hole: #2980b9
$alizarin: #e74c3c
$pomegranate: #c0392b
$amethyst: #9b59b6
$wisteria: #8e44ad
$clouds: #ecf0f1
$silver: #bdc3c7
$wet-asphalt: #34495e
$midnight-blue: #2c3e50
$concrete: #95a5a6
$asbestos: #7f8c8d

$hover: rgba(26, 188, 156, 0.03)
$li-hover: #f7f7f7

// numbers
$grid: 8px
$grid-gutter: 12px
$button-default-height: 30
$button-desktop-height: 50
$breakpoint: 768
$button-min-width: 140px
