@import "src/assets/stylessheets/variables"

.actions
    position: relative

    .action-list
        position: absolute
        right: calc(100% + 14px)
        top: -9px
        z-index: 1
        background: $white
        list-style: none
        width: 100px
        border: 1px solid $silver
        min-width: 150px

        li
            padding: $grid $grid-gutter
            cursor: pointer
            user-select: none

            &:first-of-type
                &::before,
                &::after
                    content: ""
                    position: absolute
                    border-top: 14px solid transparent
                    border-bottom: 14px solid transparent
                    top: 3px

                &::before
                    border-left: 14px solid $silver
                    right: -14px

                &::after
                    border-left: 14px solid $white
                    right: -12px

            &:hover
                background: $li-hover

                &:first-of-type::after
                    border-left-color: $li-hover

            + li
                border-top: 1px solid $clouds
