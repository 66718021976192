@import "src/assets/stylessheets/variables"

.general-form
    display: grid
    column-gap: $grid-gutter*2
    row-gap: 4px
    padding: $grid-gutter
    border-top: 1px solid $green-sea
    box-sizing: border-box

    .splitter
        display: grid
        grid-template-columns: 1fr 1fr
        row-gap: 4px
        column-gap: $grid-gutter

        &.by-three
            grid-template-columns: 1fr 1fr 1fr

    input,
    textarea,
    .select,
    select
        width: 100%
        height: 40px
        line-height: 40px
        font-size: 14px
        box-sizing: border-box
        color: $black
        padding: 0 $grid
        border-radius: 4px

    input,
    .select,
    textarea,
    select
        border: none
        background: transparentize($asbestos, .8)

        &:focus
            outline: none
            box-shadow: inset 0 0 0 1px $emerald

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    input:-webkit-autofill,
    input:-webkit-autofill:focus
        transition: background-color 0s 600000s, color 0s 600000s !important

    textarea
        height: 172px
        line-height: 20px
        resize: none
        font-family: Roboto, Verdana, sans-serif
        -webkit-font-smoothing: antialiased
        -moz-osx-font-smoothing: grayscale

    .select
        padding: 0

        .select__control
            background: transparent
            border: none
            box-shadow: none
            border-radius: 0

            &.select__control--is-focused
                box-shadow: inset 0 0 0 1px $emerald

        .select__value-container
            padding: 0 $grid

        .select__input-container
            margin: 0
            padding: 0
            color: $black

            input:focus
                box-shadow: none

        .select__placeholder
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        .select__single-value
            color: $black

        .select__menu
            background: $clouds

            .select__menu-list
                .select__option,
                .select__menu-notice
                    line-height: 20px
                    min-height: 36px

                    &:hover
                        background: $silver

                .select__option--is-focused
                    background: $silver

                .select__option--is-selected
                    background: $turquoise

    .field
        label
            display: block
            padding-bottom: calc($grid / 2)

        + .field:not(.splitter .field)
            padding-top: calc($grid / 2)

        input + button
            margin-top: calc($grid / 2)

        h3
            padding-top: $grid-gutter

    .password-field
        position: relative

        svg
            position: absolute
            height: 30px
            width: 30px
            right: 10px
            bottom: 5px
            fill: $wet-asphalt
            cursor: pointer
            user-select: none

            &.hollow
                pointer-events: none

            &:hover
                fill: $black
