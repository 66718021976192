@import "src/assets/stylessheets/variables"

.search
    position: relative
    left: 3px
    border-radius: 4px 0 0 4px
    padding: 0 $grid
    color: $black
    background: transparentize($asbestos, .8)
    border: none

    &:focus
        box-shadow: inset 0 0 0 1px $emerald
        outline: none

.search-wrapper
    display: flex

.button-wrapper
    position: relative
